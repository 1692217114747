<div class="modal-header">
  <h4><span *ngIf="!activeReservation">New</span> Reservation</h4>
</div>
<div class="modal-content">
  <form [formGroup]="reservationForm" class="p-4" autocomplete="off">
    <legend class="text-center">Table: {{ table.name }}</legend>
    <legend class="text-center">
      <i class="bi bi-people-fill"></i> {{ table.capacity }}
    </legend>
    <legend class="text-center text-danger" *ngIf="blocked">
      Someone just booked it
    </legend>

    <div class="mb-3">
      <label for="name" class="form-label">Name</label>
      <input
        id="name"
        class="form-control"
        type="text"
        formControlName="name"
        autocomplete="false"
        data-cy="newReservationFormNameInput"
      />
      <div
        *ngIf="
          reservationForm.get('name')?.invalid &&
          (reservationForm.get('name')?.dirty ||
            reservationForm.get('name')?.touched)
        "
        class="text-danger"
      >
        <div *ngIf="reservationForm.get('name')?.errors?.['required']">
          Name is required
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label for="phone" class="form-label">Phone</label>

      <input
        id="phone"
        class="form-control"
        type="text"
        formControlName="phone"
        data-cy="newReservationFormPhoneInput"
      />

      <div
        *ngIf="
          reservationForm.get('phone')?.invalid &&
          (reservationForm.get('phone')?.dirty ||
            reservationForm.get('phone')?.touched)
        "
        class="text-danger"
      >
        <div *ngIf="reservationForm.get('phone')?.errors?.['required']">
          Phone is required
        </div>
        <div *ngIf="reservationForm.get('phone')?.errors?.['pattern']">
          Phone is invalid
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label for="number-of-people" class="form-label"
        >Number of people <i>(Max {{ table.capacity }} people)</i></label
      >

      <input
        id="number-of-people"
        class="form-control"
        type="number"
        min="1"
        [max]="table.capacity"
        formControlName="numberOfPeople"
        data-cy="newReservationFormNumberOfPeopleInput"
      />

      <div
        *ngIf="
          reservationForm.get('numberOfPeople')?.invalid &&
          (reservationForm.get('numberOfPeople')?.dirty ||
            reservationForm.get('numberOfPeople')?.touched)
        "
        class="text-danger"
      >
        <div
          *ngIf="reservationForm.get('numberOfPeople')?.errors?.['required']"
        >
          Invalid number of people
        </div>
        <div *ngIf="reservationForm.get('numberOfPeople')?.errors?.['min']">
          It should be grater than 0
        </div>
        <div *ngIf="reservationForm.get('numberOfPeople')?.errors?.['max']">
          Exceeds table capacity
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer d-flex justify-content-between">
  <button
    class="btn btn-outline-secondary"
    (click)="cancelReservation()"
    [disabled]="isLoading"
  >
    Cancel reservation</button
  ><button
    class="btn btn-primary"
    [disabled]="!reservationForm.valid || blocked || isLoading"
    (click)="openConfirmModal()"
    data-cy="newReservationFormSubmitButton"
  >
    <span
      class="spinner-grow spinner-grow-sm"
      role="status"
      aria-hidden="true"
      *ngIf="isLoading"
    ></span>
    {{ activeReservation ? "Update" : "Create" }}
  </button>
</div>
