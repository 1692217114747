<div
  class="d-flex justify-content-center align-items-center vh-100"
  *ngIf="apiRequestStatus$ | async as apiRequestStatus"
>
  <form
    data-cy="loginForm"
    [formGroup]="loginForm"
    class="d-flex flex-column justify-content-center align-items-center border border-light rounded p-3 bg-light"
    (ngSubmit)="sendLoginRequest()"
  >
    <legend class="text-center">Reservations</legend>
    <div class="mb-3">
      <input
        id="email"
        class="form-control"
        type="text"
        formControlName="email"
        placeholder="Email"
        data-cy="email"
      />

      <div
        *ngIf="
          loginForm.get('email')?.invalid &&
          (loginForm.get('email')?.dirty || loginForm.get('email')?.touched)
        "
        class="text-danger"
      >
        <div *ngIf="loginForm.get('email')?.errors?.['required']">
          Email is required
        </div>
        <div *ngIf="loginForm.get('email')?.errors?.['email']">
          Invalid email
        </div>
      </div>
    </div>

    <div class="mb-3">
      <input
        id="password"
        class="form-control"
        type="password"
        formControlName="password"
        placeholder="Password"
        data-cy="password"
      />

      <div
        *ngIf="
          loginForm.get('password')?.invalid &&
          (loginForm.get('password')?.dirty ||
            loginForm.get('password')?.touched)
        "
        class="text-danger"
      >
        <div *ngIf="loginForm.get('password')?.errors?.['required']">
          Password is required
        </div>
      </div>
    </div>

    <div class="mb-3 d-flex flex-column">
      <button
        class="btn btn-primary"
        [disabled]="loginForm.invalid || apiRequestStatus.status === 'loading'"
        data-cy="login"
      >
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
          *ngIf="apiRequestStatus.status === 'loading'"
        ></span>
        Login<span *ngIf="apiRequestStatus.status === 'loading'">...</span>
      </button>

      <button
        type="button"
        class="btn btn-link"
        [routerLink]="['/new-account']"
        [disabled]="apiRequestStatus.status === 'loading'"
      >
        Create new account
      </button>

      <div class="mt-4">
        <div
          id="g_id_onload"
          data-client_id="454177337065-ib91hg5mr0jehfm0b9glltbctjdq9tsi.apps.googleusercontent.com"
          data-context="use"
          data-ux_mode="popup"
          data-nonce=""
          data-auto_prompt="false"
          data-callback="handleGoogleAuthResponse"
        ></div>

        <div
          class="g_id_signin"
          data-type="standard"
          data-shape="pill"
          data-theme="filled_blue"
          data-text="signin_with"
          data-size="large"
          data-logo_alignment="left"
        ></div>
      </div>
    </div>
    <div *ngIf="apiRequestStatus.error" class="alert alert-danger">
      {{ apiRequestStatus.error.message }}
    </div>
  </form>
</div>
