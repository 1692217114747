<nav
  class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-between"
>
  <h4 data-cy="pageTitle">Reservations</h4>

  <div><span data-cy="userType">Account</span>: <app-logout></app-logout></div>
</nav>
<div class="container text-center" *ngIf="vm$ | async as vm">
  <div
    class="row row-cols-4"
    *ngIf="
      vm.apiRequestStatus.status !== apiRequestStatus.loading;
      else Loading
    "
  >
    <div class="col p-2" *ngFor="let table of vm.tables; index as i">
      <app-table
        [data]="table"
        [disabled]="table.status !== tableStatus.available"
        (openForm)="openForm($event)"
      ></app-table>
    </div>
  </div>
</div>

<ng-template #Loading>
  <div class="d-flex justify-content-center">
    <div class="spinner-border mt-5" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
