<button
  class="btn w-100"
  style="height: 15em"
  [ngClass]="{
    'btn-secondary': data.status === tableStatus.available,
    'btn-warning': data.status === tableStatus.reserved,
    'btn-danger': data.status === tableStatus.occupied
  }"
  (click)="onOpenForm()"
  [disabled]="disabled"
  data-cy="openMakeAReservationModalButton"
>
  <p>Table: {{ data.name }}</p>
  <p><i class="bi bi-people-fill"></i> {{ data.capacity }}</p>
</button>
