<nav
  class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-between"
>
  <div>
    <button class="btn" [disabled]="true">Table Status</button>
    <a class="btn ms-2" [routerLink]="['/statistics']">Statistics</a>
  </div>

  <div><span data-cy="userType">Admin</span>: <app-logout></app-logout></div>
</nav>

<div class="container text-center" *ngIf="vm$ | async as vm">
  <div
    class="row row-cols-4"
    *ngIf="
      vm.apiRequestStatus.status !== apiRequestStatus.loading;
      else Loading
    "
  >
    <div class="col p-2" *ngFor="let table of vm.tables; index as i">
      <app-table [data]="table" (openForm)="openForm($event)"></app-table>
    </div>
  </div>
</div>

<ng-template #Loading>
  <div class="d-flex justify-content-center">
    <div class="spinner-border mt-5" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
