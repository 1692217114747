<nav
  class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-between"
>
  <div>
    <a class="btn" [routerLink]="['/table-status']">Table Status</a>
    <button class="btn ms-2" [disabled]="true">Statistics</button>
  </div>

  <div>Admin: <app-logout></app-logout></div>
</nav>

<div class="button-groups text-center">
  <button class="btn btn-outline-primary" (click)="thisWeek()">
    This week
  </button>
  <button class="btn btn-outline-primary" (click)="thisMonth()">
    This month
  </button>
</div>
<div
  echarts
  [options]="options"
  [merge]="mergeOptions"
  class="demo-chart"
></div>

<div class="d-flex justify-content-center">
  <app-datepicker-range-popup
    (rangeSelected)="updateDateRange($event)"
  ></app-datepicker-range-popup
  ><button
    class="btn btn-outline-primary ms-4"
    (click)="customDateRange()"
    [disabled]="!dateRange.start || !dateRange.end"
  >
    Custom
  </button>
</div>
